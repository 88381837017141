import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pos-product-search"
export default class extends Controller {
  static targets = [ 'input' ]

  search () {
    const _query = this.inputTarget.value.trim()

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (_query.length > 2 && _query != this.query) {
        this.query = _query
        this.element.requestSubmit()
      }
    }, 200)
  }
}
