import { Controller } from '@hotwired/stimulus'
import Noty from 'noty'
import 'noty/lib/noty.css'
import 'noty/lib/themes/relax.css'

export default class extends Controller {
  static targets = [ 'type', 'message' ]

  connect () {
    new Noty({
      layout: 'bottomLeft',
      text: this.messageTarget.value,
      type: this.getNotificationType(),
      theme: 'relax',
      progressBar: true,
      timeout: 5000,
    }).show()
  }

  getNotificationType () {
    switch (this.typeTarget.value) {
      case 'notice':
        return 'info'
      case 'success':
        return 'success'
      case 'error':
        return 'error'
      case 'alert':
        return 'warning'
      default:
        return 'info'
    }
  }
}
